import React, { useState } from "react";
import { Link } from 'react-router-dom';
import logo from './image/vetrilogo.jpg';
import "./Navbar.css";
import QuoteForm from "./contactForm";
import 'boxicons/css/boxicons.min.css'; 

const courseIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isQuoteFormOpen, setIsQuoteFormOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    if (isOpen) {
      setIsDropdownOpen(false); 
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const handleLinkClick = () => {
    if (window.innerWidth <= 768) { // Close menu on mobile
      setIsOpen(false);
    }
    closeDropdown();
  };

  const openQuoteForm = () => {
    setIsQuoteFormOpen(true);
  };

  const closeQuoteForm = () => {
    setIsQuoteFormOpen(false);
  };

  return (
    <div>
      <header className="navbar">
        <nav className="navbar-bottom">
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="VIS" />
            </Link>
          </div>
          <button className="menu-toggle" onClick={toggleMenu}>
            {isOpen ? "✖" : "☰"}
          </button>
          <ul className={`nav-links ${isOpen ? "open" : ""}`}>
            <li id="menu">
              <Link to="/" onClick={handleLinkClick}>VTS PROCESS</Link>
            </li>
            <li className="dropdown" id="menu">
              <button onClick={toggleDropdown} className="dropdown-toggle">
                OUR TRAININGS {isDropdownOpen ? <box-icon name='chevron-up' color='#FF8016' type='solid'></box-icon> : <box-icon type='solid' name='chevron-down' color='#FF8016'></box-icon>}
              </button>
              {isDropdownOpen && (
                <ul className="dropdown-menu" style={{ listStyleType: "none" }}>
                  <div className="dropdown-row">
                    <div className="dropdown-menu-r">
                      <h1 className="c-head-nav">Coding</h1>
                      <div className="dropdown-menu-cl">
                        <div className="dropdown-column">
                          <h1><Link to={`/course/${courseIds[0]}`} onClick={handleLinkClick}>Frontend Developer</Link></h1>
                          <h1><Link to={`/course/${courseIds[1]}`} onClick={handleLinkClick}>Backend Developer (Python)</Link></h1>     
                          <h1><Link to={`/course/${courseIds[2]}`} onClick={handleLinkClick}>Backend Developer (Node.Js)</Link></h1>
                          <h1><Link to={`/course/${courseIds[3]}`} onClick={handleLinkClick}>Backend Developer (Java)</Link></h1>
                          <h1><Link to={`/course/${courseIds[4]}`} onClick={handleLinkClick}>MERN Stack Developer</Link></h1> 
                        </div>
                        <div className="dropdown-column">
                          <h1><Link to={`/course/${courseIds[5]}`} onClick={handleLinkClick}>Python Fullstack Developer</Link></h1>
                          <h1><Link to={`/course/${courseIds[6]}`} onClick={handleLinkClick}>Java Fullstack Developer</Link></h1>
                          <h1><Link to={`/course/${courseIds[11]}`} onClick={handleLinkClick}>Mobile App Development</Link></h1>
                          <h1><Link to={`/course/${courseIds[9]}`} onClick={handleLinkClick}>Data Analytics</Link></h1>
                          <h1><Link to={`/course/${courseIds[10]}`} onClick={handleLinkClick}>Data Science</Link></h1>
                        </div>
                      </div>
                    </div>
                    <div className="dropdown-menu-r">
                      <h1 className="c-head-nav">Non-Coding</h1>
                      <div className="dropdown-menu-cl1">
                        <div className="dropdown-column">
                        <h1><Link to={`/course/${courseIds[7]}`} onClick={handleLinkClick}>UI/UX Designing</Link></h1>
                          <h1><Link to={`/course/${courseIds[8]}`} onClick={handleLinkClick}>Digital Marketing</Link></h1>
                          <h1><Link to={`/course/${courseIds[12]}`} onClick={handleLinkClick}>Software Testing</Link></h1>
                        </div>
                        <div className="dropdown-column">
                          <h1><Link to={`/course/${courseIds[14]}`} onClick={handleLinkClick}>AWS</Link></h1>
                          <h1><Link to={`/course/${courseIds[13]}`} onClick={handleLinkClick}>Business Analytics</Link></h1>

                        </div>
                      </div>
                    </div>
                  </div>
                </ul>
              )}
            </li>
            <li id="menu">
              <Link to="/Careers" onClick={handleLinkClick}>CAREERS</Link>
            </li>
            {/* <li>
              <button onClick={openQuoteForm} className="quote-button">
                ENQUIRY
              </button>
            </li> */}
          </ul>
        </nav>
        <QuoteForm isOpen={isQuoteFormOpen} onClose={closeQuoteForm} />
      </header>
    </div>
  );
};

export default Navbar;
