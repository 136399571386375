import React from 'react';
import Slider from 'react-slick';
import './project.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import pro1 from './image/petstore.png';
import pro2 from './image/pro2.jpg';
import pro3 from './image/hydro.png';
import pro4 from './image/build.png';
import moun from './image/map.gif';
import hire1 from './image/hire1.png';
import hire2 from './image/hire2.png';

const Procarousel = () => {
  const projects = [
    {
      id: 1,
      num: '#1',
      title: 'Pet Shop Website - Fullstack',
      details: [
        { text: 'Developed a full-stack pet shop platform with MERN.' },
        { text: 'Facilitated product browsing and purchasing.' },
        { text: 'Implemented secure authentication and data management.' },
        { text: 'Created an interactive frontend with React.' },
        { text: 'Managed data with MongoDB.' }
      ],
      imgSrc: pro1
    },
    {
      id: 2,
      num: '#2',
      title: 'Sweetshop Website - Frontend',
      details: [
        { text: 'Developed a sweet shop website using Python.' },
        { text: 'Enabled product browsing and ordering.' },
        { text: 'Integrated secure user authentication.' },
        { text: 'Designed an engaging interface.' },
        { text: 'Used Python for backend and data management.' }
      ],
      imgSrc: pro2
    },
    {
      id: 3,
      num: '#3',
      title: 'HydroTork Website - FullStack',
      details: [
        { text: 'Showcased hydro products with a responsive site.' },
        { text: 'Implemented direct enquiry feature.' },
        { text: 'Integrated secure backend for data.' },
        { text: 'Ensured intuitive design and navigation.' },
        { text: 'Enhanced engagement with product visuals.' }
      ],
      imgSrc: pro3
    },
    {
      id: 4,
      num: '#4',
      title: 'Builders Website - Frontend',
      details: [
        { text: 'Developed a comprehensive builders website with MERN.' },
        { text: 'Facilitated product browsing and purchasing.' },
        { text: 'Implemented secure authentication and data management.' },
        { text: 'Created an interactive React frontend.' },
        { text: 'Managed data with MongoDB.' }
      ],
      imgSrc: pro4
    }
  ];
  
  const settings = {
    dots: true,
    infinite: true,
    speed:1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 690, // Tablet and smaller screens
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 1020, // Laptop screens
        settings: {
          slidesToShow: 2
        }
      }
    ]
  };

  return (
    <div>
      <section className='project'>
        <div className='procar-container' data-aos="fade-up">
          <div className='car-text'>
            <h1 className='car-h1'>Work on real projects and build a solid <span className='proo-span'>practical understanding.</span></h1>
          </div>
          <Slider {...settings}>
            {projects.map((project) => (
              <div className='procar-col' key={project.id} data-aos="fade-up" data-aos-delay={project.id * 100}>
                <div className='procar-card'>
                  <div className='card-inner'>
                    <div className='card-num'>
                      <h3 className='card-h3'>{project.num}</h3>
                    </div>
                    <div className='card-logo'>
                      <img src={project.imgSrc} alt='project' />
                    </div>
                  </div>
                  <div className='inner-text'>
                    <h1 className='inner-h1'>{project.title}</h1>
                    <ul className='inner-ul'>
                      {project.details.map((detail, index) => (
                        <li className='inner-li' key={index}>
                          <span>{detail.text}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
      <section className="placement" data-aos="fade-up">
        <img src={moun} alt="Placement Map" />
      </section>
      <section className='hire'>
        <div className='hire-cont'>
            <h1 className='hire-title'>Current <span className='hire-span'>Openings</span> </h1>
            <div className='hire-row'>
                <div className='hire-col'>
                    <div className='hire-img'>
                   <img src={hire1}></img>
                   </div>
                </div>
                <div className='hire-col'>
                <div className='hire-img'>
                   <img src={hire2}></img>
                   </div>
                </div>
            </div>
        </div>

      </section>
    </div>
  );
};

export default Procarousel;
