import React from 'react';
import './ImagePopup.css'; // Ensure the path is correct
import popimg from './image/digest.gif'
const ImagePopup = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>✖</button>
        <img src={popimg} alt="Popup" />
      </div>
    </div>
  );
};

export default ImagePopup;