import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import lo1 from './image/Study abroad.gif'; 
import lo2 from './image/trainers.gif'; 
import lo3 from './image/placement.gif';
import lo4 from './image/rating.gif';

const Dashbord = () => {
  const [studentCount, setStudentCount] = useState(0);
  const [classCount, setClassCount] = useState(0);
  const [satisfactionRate, setSatisfactionRate] = useState(0);
  const [instructorsCount, setInstructorsCount] = useState(0);
  const [showCards, setShowCards] = useState(false);

  useEffect(() => {
    const incrementNumber = (setter, target) => {
      let current = 0;
      const increment = target / 100;
      const timer = setInterval(() => {
        current += increment;
        if (current >= target) {
          clearInterval(timer);
          setter(target);
        } else {
          setter(Math.ceil(current));
        }
      }, 20);
    };

    incrementNumber(setStudentCount, 200);
    incrementNumber(setClassCount, 20);
    incrementNumber(setSatisfactionRate, 100);
    incrementNumber(setInstructorsCount, 10);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector('.dashboard');
      const { top, bottom } = section.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (top < windowHeight && bottom > 0) {
        setShowCards(true);
      } else {
        setShowCards(false); // Optional: to hide cards when out of view
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <section className="dashboard">
      <div className="card-container">
        <div className='card-row'>
          <div className={`card ${showCards ? 'show' : ''}`}>
            <div className="card-content">
              <img src={lo1} alt="Students" />
              <h3>{studentCount}+</h3>
              <h5>STUDENTS</h5>
            </div>
          </div>
          <div className={`card ${showCards ? 'show' : ''}`}>
            <div className="card-content">
              <img src={lo2} alt="Industry Expert Trainers" />
              <h3>{classCount}+</h3>
              <h5>INDUSTRY EXPERT TRAINERS</h5>
            </div>
          </div>
          <div className={`card ${showCards ? 'show' : ''}`}>
            <div className="card-content">
              <img src={lo4} alt="Satisfaction Rate" />
              <h3>{satisfactionRate}%</h3>
              <h5>SATISFACTION RATE</h5>
            </div>
          </div>
          <div className={`card ${showCards ? 'show' : ''}`}>
            <div className="card-content">
              <img src={lo3} alt="Placement Coordinators" />
              <h3>{instructorsCount}+</h3>
              <h5>PLACEMENT COORDINATORS</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dashbord;
