import React, { useEffect } from 'react';
import './About.css';
import Aboutimg from './image/5.png';

const About = () => {
  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll('.cloum1, .cloum2');
      elements.forEach(element => {
        const rect = element.getBoundingClientRect();
        if (rect.top < window.innerHeight && rect.bottom >= 0) {
          element.classList.add('visible');
        } else {
          element.classList.remove('visible');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <section className='about'>
        <div className="about-container">
          <h2 className='abo-head'>
            <span className='abo-span'>VETRI</span> Technology <span className='abo-span'>Solutions</span>
          </h2>

          <div className="about-row">
            <div className="cloum1">
              <div className='about-img'>
                <img src={Aboutimg} alt="About Us" />
              </div>
            </div>
            <div className="cloum2">
              <h2 className='head-do'>
                VTS BUILD THE SKILLS TO DRIVE YOUR <br /> <span className='abo-span'>IT CAREER!!</span>
              </h2>
              <p className='about-page'>
                VTS is the No.1 Software Training Institute in Tamilnadu - Based out of Tenkasi & Tirunelveli
                Providing 100% Placement in our own IT Company <a href='https://vetriit.com/' className='about-an'>Vetri IT Systems</a> to the graduated students from IT and Non-IT streams.
              </p>
              <p className='about-page'>
                VTS has a team of highly experienced trainers who have worked on a wide range of projects and have a deep understanding of the development and testing process. We believe in providing hands-on training to our trainees, which helps them to gain practical experience and prepare them for real-world scenarios.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
