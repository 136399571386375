import React from 'react';
import  Internship from './Internship.js';
import Project from './project.js';

const Career = () => {
    
  return (
    <>
      <Internship/>
      <Project/>
    </>
    
  );
};

export default Career;
