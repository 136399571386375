import React from 'react';
import './Fqa.css'; 

const FAQ = () => {
  return (
    <section className='question'>
      <h2>Trainees <span>Query</span></h2>
      <div className="content">
        <details>  
          <summary>Where the VTS trainees will get a job?</summary>
          <div className="faq__content">
            <p>Our VTS trainees will be placed in our Vetri IT Systems Private Limited.</p>
          </div>
        </details>
        <details>
          <summary>What is the Internship Period?</summary>
          <div className="faq__content">
            <p>After a successful completion of training and obtaining a certificate, the following 3 months will be an Internship with Vetri IT Systems Pvt Ltd.</p>
          </div>
        </details>
        <details >
          <summary>Any exams in the training period?</summary>
          <div className="faq__content">
            <p>Yes, definitely. Weekly exams and reviews will be conducted. Successful completion of training and internship is strictly required for placement.</p>
          </div>
        </details>
        <details >
          <summary>Only exam cleared candidates eligible for job?</summary>
          <div className="faq__content">
            <p>Yes, the candidate has to clear the exams and get the certificate to join the internships.</p>
          </div>
        </details>
        <details >
          <summary>Is it necessary to complete the Internship for the job?</summary>
          <div className="faq__content">
            <p>Yes, completing the internship is necessary for placement.</p>
          </div>
        </details>
      </div>
    </section>
  );
};

export default FAQ;
