import React, { useEffect } from "react";
import './intership.css';
import in1 from './image/in1.png';
import in2 from './image/in2.png';
import in3 from './image/in3.png';
import in4 from './image/in4.png';
import in5 from './image/in5.png';
import in6 from './image/ve1.png';
import paee from './image/internshipmain.jpg';


const Animation = () => {
 

  return (
    <div>
      <section className="te-con" data-aos="fade-up">
        <div className="ani-pa">
          <h2>INTERNSHIP</h2>
          <h2>INTERNSHIP</h2>
        </div>
      </section>
      <section className="inten">
        <div className="inten-contai">
          <div className="inten-row1">
            <div className="tex-in">
              <h1 className="intern-heading">Internship With <span className="intern-span"> VTS</span></h1>
              <p>An intern is a trainee who has signed on with an organisation for <br /> a brief period. An intern’s goal is to gain work experience, <br /> occasionally some university credit, and always an overall <br /> feel for the industry they’re interning in.</p>
              <img width={500} height={400} src={paee} alt="Internship Overview" />
            </div>
            <div className="tex-na">
              <div className="par-con1">
                <div className="par-row1">
                  <img width={50} height={50} src={in1} alt="Task Fulfillment" />
                  <div className="te-ps1">
                    <h3>Fulfilling tasks assigned by a supervisor.</h3>
                  </div>
                </div>
              </div>
              <div className="par-con1" >
                <div className="par-row1">
                  <img width={50} height={50} src={in2} alt="Clerical Duties" />
                  <div className="te-ps1">
                    <h3>Performing clerical duties.</h3>
                  </div>
                </div>
              </div>
              <div className="par-con1">
                <div className="par-row1">
                  <img width={50} height={50} src={in3} alt="Social Media Management" />
                  <div className="te-ps1">
                    <h3>Managing social media accounts.</h3>
                  </div>
                </div>
              </div>
              <div className="par-con1" >
                <div className="par-row1">
                  <img width={50} height={50} src={in4} alt="Event Handling" />
                  <div className="te-ps1">
                    <h3>Event handling or planning.</h3>
                  </div>
                </div>
              </div>
              <div className="par-con1" >
                <div className="par-row1">
                  <img width={50} height={50} src={in5} alt="Job Shadowing" />
                  <div className="te-ps1">
                    <h3>Job shadowing.</h3>
                  </div>
                </div>
              </div>
              <div className="par-con1" >
                <div className="par-row1">
                  <img width={50} height={50} src={in6} alt="Technical Skills" />
                  <div className="te-ps1">
                    <h3>Learning technical skills related to the industry.</h3>
                  </div>
                </div>
              </div>
              <div className="las-par" >
                <p>In VTS once the 6 months training is completed, the student will move to the Intern level for a period of 3 months. Based on the internship performance, the roles will be defined.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Animation;
