import React, { useEffect, useState } from 'react';
import './Reviewsection.css';
import Im1 from './image/certificate.png';
// import C1 from './image/c1.jpg';
import C2 from './image/c2.jpg';
// import C3 from './image/c33.jpg';
import C4 from './image/c4.jpg';
import C5 from './image/c5.jpg';

const ReviewSection = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector('.review-section');
      const rect = section.getBoundingClientRect();
      if (rect.top <= window.innerHeight && rect.bottom >= 0) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div>
      <section className="review-section" data-aos="fade-up">
        <div className="review-container">
          <div className="row">
            <div className={`review-cloum1 ${isVisible ? 'visible' : ''}`} data-aos="fade-right">
              <h1 className='review-head1'>
                VTS <span className='review-span'> Credentials </span>
              </h1>
              <p className='review-para'>
                VTS provides you a training completion certificate with an online verification code.
              </p>
              <p className='review-para'>
                Once training is complete at VTS, you will receive a guaranteed internship with our own IT organisation <br></br><a href='https://vetriit.com/' className='about-an'>Vetri IT Systems</a> Private Limited.
              </p>
              <div className='r-cl-row'>
                <div className='r-cl-col'>
                  <div className='cl-col-img'>
                    <img src={Im1} alt="award-1" />
                  </div>
                  <p className='c-col-p'>VTS Certificate</p>
                </div>
              </div>
            </div>
            <div className={`review-cloum1 ${isVisible ? 'visible' : ''}`} data-aos="fade-left">
              <div className="cloum2-tit">
                <h2 className="tit-h2">Top Ranked Students are from here</h2>
              </div>
              <div className="row3">
                <div className="review-slider1">
                  <ul className="auto_slider" data-aos="fade-up">
                    {/* <li><img className="review-img" src={C1} alt="slider1" /></li> */}
                    <li><img className="review-img" src={C2} alt="slider1" /></li>
                    {/* <li><img className="review-img" src={C3} alt="slider1" /></li> */}
                    <li><img className="review-img" src={C4} alt="slider1" /></li>
                    <li><img className="review-img" src={C5} alt="slider1" /></li>
                  </ul>
                  <ul className="auto_slider" data-aos="fade-up" data-aos-delay="200">
                    {/* <li><img className="review-img" src={C1} alt="slider1" /></li> */}
                    <li><img className="review-img" src={C2} alt="slider1" /></li>
                    {/* <li><img className="review-img" src={C3} alt="slider1" /></li> */}
                    <li><img className="review-img" src={C4} alt="slider1" /></li>
                    <li><img className="review-img" src={C5} alt="slider1" /></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ReviewSection;
