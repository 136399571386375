import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Contact from './components/contact.js';
import CourseDetails from './components/courseDetails.js';
import Navbar from './components/Navbar.js';
import Home from './components/Home.js';
import Careers from './components/careers.js';

const App = () => {
  return (
    <Router>
      <Navbar/>
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/course/:id" element={<CourseDetails />} />
          <Route path="/careers" element={<Careers />} />
      </Routes>
      <Contact/>
    </Router>
  );
};
export default App;
