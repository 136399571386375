import React from 'react';
import { useParams } from 'react-router-dom';
import './coursedetails.css';
import javaImg from './image/java.png';
import datascience from './image/datasc.png';
import datanalt from './image/dataan.png';
import App from './image/app.png';
import Front from './image/front.png';
import Back from './image/back1.png';
import Back1 from './image/back2.png';
import Back2 from './image/back3.png';
import Mern from './image/mern.png';
import Aws from './image/aws.png';
import Python from './image/python.png';
import Bus from './image/bus.png';
import Digital from './image/digital.png';
import Softtest from './image/soft.png';
import Cyber from './image/soc.png';
import Ui from './image/ui.png';

import cr from './image/SOFTWARE TESTING ROAD MAP.png';
import cr1 from './image/BUSINESS ANALYST ROAD MAP.png';
import cr2 from './image/AWS ROADMAP.png';
import cr3 from './image/CYBERSECURITY ROADMAP.png';
import cr4 from './image/PYTHON FULL STACK ROAD MAP.png';
import cr5 from './image/MERN  STACK ROAD MAP.png';
import cr6 from './image/FRONT END DEVELOPER ROAD MAP.png';
import cr7 from './image/JAVA FULL STACK ROAD MAP.png';
import cr8 from './image/BACKEND DEVELOPER ROAD MAP-MODULE 1.png';
import cr14 from './image/BACKEND DEVELOPER ROAD MAP-MODULE 2.png';
import cr15 from './image/BACKEND DEVELOPER ROAD MAP-MODULE 3.png';
import cr9 from './image/DATA SCIENCE ROAD MAP.png';
import cr10 from './image/DATA ANALYTICS ROAD MAP.png';
import cr11 from './image/MOBILE APP DEVELOPER.png';
import cr12 from './image/BUSINESS ANALYST ROAD MAP.png';
import cr13 from './image/UI_UX DESIGNING ROAD MAP.png';

const academicProjects = [
    {
        id: 1,
        name: "Frontend Developer",
        description: "Frontend development is crucial as it directly shapes the user experience by designing intuitive interfaces.",
        duration: "3 Months",
        eligibility: "Any degree",
        image: Front,
        courseimage: cr6,
        mode: ["Online", "Classroom"],
    },
    {
        id: 2,
        name: "Backend Developer (python)",
        description: "Java is a high-level object-oriented programming language and computing platform developed by Sun Microsystems.",
        duration: "3 Months",
        eligibility: "Any degree",
        image: Back,
        courseimage: cr8,
        mode: ["Online", "Classroom"],
    },
    {
        id: 3,
        name: "Backend Developer (node.js)",
        description: "Spring framework is a popular Java framework for building robust backend applications.",
        duration: "3 Months",
        eligibility: "Any degree",
        image: Back1,
        courseimage: cr14,
        mode: ["Online", "Classroom"],
    },
    {
        id: 4,
        name: "Backend Developer (java)",
        description: "Hibernate is a framework that simplifies database interactions in Java applications.",
        duration: "3 Months",
        eligibility: "Any degree",
        image: Back2,
        courseimage: cr15,
        mode: ["Online", "Classroom"],
    },
    {
        id: 5,
        name: "MERN Stack",
        description: "The MERN stack is a popular web development framework consisting of MongoDB, Express.js, React.js, and Node.js.",
        duration: "6 Months",
        eligibility: "Any degree",
        image: Mern,
        courseimage: cr5,
        mode: ["Online", "Classroom"],
    },
    {
        id: 6,
        name: "Python Fullstack",
        description: "Python Fullstack development covers both frontend and backend development using Python.",
        duration: "6 Months",
        eligibility: "Any degree",
        image: Python,
        courseimage: cr4,
        mode: ["Online", "Classroom"],
    },
    {
        id: 7,
        name: "Java Fullstack",
        description: "Java Fullstack development encompasses both frontend and backend development using Java.",
        duration: "6 Months",
        eligibility: "Any degree",
        image: javaImg,
        courseimage: cr7,
        mode: ["Online", "Classroom"],
    },
    {
      id: 8,
      name: "UI/UX Designing",
      description: "UI/UX Designing involves creating intuitive and engaging user interfaces and experiences.",
      duration: "5 Months",
      eligibility: "Any degree",
      image: Ui,
      courseimage: cr13,
      mode: ["Online", "Classroom"],
  },
  {
      id:9,
      name: "Digital Marketing",
      description: "Digital marketing involves promoting products or services using digital channels.",
      duration: "5 Months",
      eligibility: "Any degree",
      image: Digital,
      courseimage: cr12,
      mode: ["Online", "Classroom"],
  },
  {
      id: 10,
      name: "Data Analytics",
      description: "Data analytics involves analyzing data to uncover insights and make data-driven decisions.",
      duration: "5 Months",
      eligibility: "Any degree",
      image: datanalt,
      courseimage: cr10,
      mode: ["Online", "Classroom"],
  },
  {
    id: 11,
    name: "Data Science",
    description: "Data science involves extracting knowledge and insights from data through various methods and algorithms.",
    duration: "4 Months",
    eligibility: "Any degree",
    image: datascience,
    courseimage: cr9,
    mode: ["Online", "Classroom"],
},
    {
        id:12,
        name: "Mobile App Developer",
        description: "App development involves creating mobile applications for various platforms.",
        duration: "7 Months",
        eligibility: "Any degree",
        image: App,
        courseimage: cr11,
        mode: ["Online", "Classroom"],
    },
    {
        id: 13,
        name: "Software Testing",
        description: "Software testing involves evaluating software applications to ensure they meet quality standards.",
        duration: "5 Months",
        eligibility: "Any degree",
        image: Softtest,
        courseimage: cr,
        mode: ["Online", "Classroom"],
    },
  
   
    {
        id: 14,
        name: "Business Analytics",
        description: "Business analytics involves analyzing data to help businesses make data-driven decisions.",
        duration: "5 Months",
        eligibility: "Any degree",
        image: Bus,
        courseimage: cr1,
        mode: ["Online", "Classroom"],
    },
    {
        id: 15,
        name: "AWS",
        description: "AWS (Amazon Web Services) is a cloud computing platform providing a range of services for building and managing applications.",
        duration: "5 Months",
        eligibility: "Any degree",
        image: Aws,
        courseimage: cr2,
        mode: ["Online", "Classroom"],
    }
];


const CourseDetails = () => {
  const { id } = useParams();
  const course = academicProjects.find(course => course.id === parseInt(id));
  if (!course) {
      return <div>Course not found!</div>;
  }
  return (
    <div>
    <section className='detail-banner'></section>
    <section className='detail'
    >
        <div className='detail-cont'>
            <div className='detail-row'>
                <div 
                    className='detail-col1'
                >
                    <h1 className='detail-h1'>{course.name}</h1>
                    <p className='detail-point-d'>{course.description}</p>
                </div>
                <div 
                    className='detail-col1'
                >
                    <div className='detail-col1-row'>
                        <div className='d-c-r-col'>
                            <div className='d-c-r-c-card'>
                                <h1 className='detail-point'>IT Training Duration</h1>
                                <p className='detail-list-point'>{course.duration}</p>
                            </div>
                        </div>
                        <div className='d-c-r-col'>
                            <div className='d-c-r-c-card'>
                                <h1 className='detail-point'>IT Training Eligibility</h1>
                                <p className='detail-list-point'>{course.eligibility}</p>
                                <p className='detail-list-point'>2021-2024 Passed Out Students</p>
                            </div>
                        </div>
                        <div className='d-c-r-col'>
                            <div className='d-c-r-c-card'>
                                <h1 className='detail-point'>IT Training Modes</h1>
                                <p className='detail-list-point'>
                                    {Array.isArray(course.mode) ? course.mode.join('/ ') : course.mode}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div className='cou-detail-img'>
            <img src={course.courseimage} alt={course.name} />
        </div>
    </section>
</div>

  );
};

export default CourseDetails;
