import React  from 'react';
import './contact.css';
import 'boxicons';
import { FaWhatsapp, FaPhone } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {
   
    return (
        <footer className="footer">
            <div className="f-container">
          
       <div className='footer-cont'>
          <div className="footer-icons">
      <a href="https://wa.me/918438558527" className="footer-icon whatsapp-icon">
        <FaWhatsapp />
      </a>
      <a href="tel:+918438558527" className="footer-icon phone-icon">
      <FaPhone style={{ transform: 'rotate(90deg)'}} />
      </a>
    </div>
</div>
                <div className="f-row">
                    <div 
                        className="footer-col" 
                    
                    >
                        <h4>Contact Details</h4>
                        <div className="f-contact">
                            
                            <div className='f-num'>
                            <a href="tel:+9184385 58527">
                              
                                <box-icon name='phone-call' color='#ff8016'></box-icon>
                                +91 84385 58527
                            </a>
                            </div>
                            <div className='f-num'>
                            <a href="mailto:joinvts@vetriit.com">
                               
                                <box-icon name='envelope' color='#ff8016'></box-icon>
                            joinvts@vetriit.com
                            </a>
                            </div>

                        </div>
                    </div>
              
                     <div 
                        className="footer-col" 
                
                    >
                        <h4>Useful Links</h4>
                        <div className="f-contact">
                        <div className='footer-val'>
                            <ul>
                            <li><Link to="/">VTS Process</Link></li>
                                <li><Link to="/">VTS IT Training</Link></li>
                            </ul>
                        </div>

                        </div>
                    </div>
                    <div 
                        className="footer-col" 
                    
                    >
                        <h4>Careers</h4>
                        <div className='footer-val'>
                            <ul>
                            <li><Link to="/careers">VIS Internship</Link></li>
                                <li><Link to="/careers">VIS Placement</Link></li>
                                <li><Link to="/careers">VIS Project</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div 
                        className="footer-col" 
                      
                    >
                        <h4>Follow Us</h4>
                        <div className="social-links">
                            <a href="https://www.facebook.com/profile.php?id=61559691146330&mibextid=LQQJ4d">
                                <box-icon name='facebook-circle' type='logo' animation='tada' color='#ffffff'></box-icon>
                            </a>
                            <a href="https://www.instagram.com/vetritechnologysolutions?igsh=MW5kODBuaGVrMjlzYw%3D%3D&utm_source=qr">
                                <box-icon name='instagram' type='logo' animation='tada' color='#ffffff'></box-icon>
                            </a>
                            <a href="https://www.linkedin.com/company/vetri-technology-solutions/">
                                <box-icon name='linkedin-square' type='logo' animation='tada' color='#ffffff'></box-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
