import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './whyfeature.css'; 
import challengeImage from './image/wc1.gif'; 
import languageImage from './image/wc2.gif';
import oneToOneImage from './image/wc3.gif'; 
import swappingImage from './image/wc4.gif'; 
import timingImage from './image/wc5.gif'; 
import timingImage1 from './image/wc6.gif'; 


const Carousel = () => {
 
  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="why">
      <div className="card-con1">
        <div className="card-wr">
          <Slider {...settings} className="carousel-slider">
            <div >
              <img src={challengeImage} alt="Challenges" className="carousel-image" />
            </div>
            <div >
              <img src={languageImage} alt="Language" className="carousel-image" />
            </div>
            <div >
              <img src={oneToOneImage} alt="One to One" className="carousel-image" />
            </div>
            <div >
              <img src={swappingImage} alt="Swapping" className="carousel-image" />
            </div>
            <div >
              <img src={timingImage} alt="Timing" className="carousel-image" />
            </div>
            <div>
              <img src={timingImage1} alt="Timing" className="carousel-image" />
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Carousel;
