import React, { useEffect } from 'react';
import './whyfeature.css';
import fimg1 from './image/one to.png';
import fimg2 from './image/placement.png';
import fimg3 from './image/certification_approval.png';
import fimg4 from './image/flexible_timing.png';
import fimg5 from './image/work_from_home.png';
import fimg6 from './image/training.png';
import fimg7 from './image/emi options.jpg';
import fimg8 from './image/of.png';

const FeaturesSection = () => {

  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll('.features-col');
      elements.forEach(element => {
        const rect = element.getBoundingClientRect();
        if (rect.top < window.innerHeight && rect.bottom >= 0) {
          element.classList.add('visible');
        } else {
          element.classList.remove('visible');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section className="features">
      <div className="features-container">
        <h2 className='why-head'>How <span className='why-span'>VTS</span>  Is Different From <span className='why-span'>Others ???</span></h2>
        <div className="features-row">
          <div className="features-col" >
            <div className="features-icon"> 
              <div className='fi-img'>
                <img src={fimg2} alt="Placement"/>
              </div>
              <h3>100% Guaranteed <br/> Placement</h3>
            </div>
          </div>
          <div className="features-col">
            <div className="features-icon">
              <div className='fi-img'>
                <img src={fimg6} alt="Trainers"/>
              </div>
              <h3>Industry Expert <br/> Trainers</h3>
            </div>
          </div>
          <div className="features-col">
            <div className="features-icon">
              <div className='fi-img'>
                <img src={fimg1} alt="Mentorship"/>
              </div>
              <h3>1 - 1 Expert <br/> Mentorship</h3>
            </div>
          </div>
          <div className="features-col">
            <div className="features-icon">
              <div className='fi-img'>
                <img src={fimg4} alt="Timings"/>
              </div>
              <h3>Flexible batch <br/> Timings</h3>
            </div>
          </div>
          <div className="features-col" >
            <div className="features-icon">
              <div className='fi-img'>
                <img src={fimg7} alt="EMI Options"/>
              </div>
              <h3>EMI Options <br/> Available</h3>
            </div>
          </div>
          <div className="features-col">
            <div className="features-icon">
              <div className='fi-img'>
                <img src={fimg5} alt="Work From Home"/>
              </div>
              <h3>Online training with Work From Home Opportunity</h3>
            </div>
          </div>
          <div className="features-col" >
            <div className="features-icon">
              <div className='fi-img'>
                <img src={fimg8} alt="Live Projects"/>
              </div>
              <h3>Work in Live <br/> Projects</h3>
            </div>
          </div>
          <div className="features-col">
            <div className="features-icon">
              <div className='fi-img'>
                <img src={fimg3} alt="Certification"/>
              </div>
              <h3>Certification Approval Guaranteed</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
