import React, { useState } from 'react';
import axios from 'axios';
import './contactForm.css';
import 'boxicons';
import enimg from './image/ve2.png'

const QuoteForm = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    services: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("http://localhost:5000/api/contact", formData);
      alert("Enquiry submitted and email sent to admin successfully");
    } catch (error) {
      console.error("Error submitting enquiry:", error.response ? error.response.data : error.message); // Log detailed error
      alert("Error submitting enquiry");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="overlay">
      <div className="popup">
        <button className="close-btn" onClick={onClose}>✖</button>
        <div className="form-section">
          <h2>FOR ENQUIRY</h2>
          <form className="form-container" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">
              <box-icon type='solid' name='user'></box-icon> Name
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="number">
              <box-icon type='solid' name='phone'></box-icon> Phone
              </label>
              <input
                type="text"
                name="number"
                value={formData.number}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">
              <box-icon type='solid' name='message-rounded-detail'></box-icon> Message
              </label>
              <input
                type="text"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </div>
            <button type="submit">ENQUIRY ➔</button>
          </form>
        </div>
        <div className="info-section">
          <h2>Boost Your Skills in VTS with 100% Placement!!</h2>
          <div className="contact-options">
            <div className="contact-option">
            <img src={enimg}></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteForm;