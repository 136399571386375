import React, { useState, useEffect } from 'react';
import CourseList from './courseList.js';
import WhySection from './why.js';
import FeaturesSection from './feature.js';
import About from './About.js';
import ReviewSection from './Reviewsection.js';
import Dashboard from './Dashboard.js';
import Fqa from './Fqa.js';
import Carcousel from './carcousel.js';
import ImagePopup from './ImagePopup.js';

const Home = () => {
  const [isImagePopupOpen, setIsImagePopupOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsImagePopupOpen(true);
    }, 2000); 

    return () => clearTimeout(timer);
  }, []);

  const handleImagePopupClose = () => {
    setIsImagePopupOpen(false);
  };
  
  return (
    <>
      <Carcousel />
      <About />
      <FeaturesSection />
      <WhySection />
      <Dashboard />
      <CourseList />
      <ReviewSection />
      <Fqa />

      <ImagePopup isOpen={isImagePopupOpen} onClose={handleImagePopupClose} />
    </>
  );
};

export default Home;